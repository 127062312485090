<script setup lang="ts">
import { usePageField } from '@voix/composables/usePageField'
import { usePageStore } from '@voix/store/pageStore'

defineLayout({
  label: 'Npiwff',
})

useAtlantisHead()

// Generated much of this at https://realfavicongenerator.net
useHead({
  htmlAttrs: {
    lang: 'en-US',
  },
  meta: [
    { name: 'msapplication-TileColor', content: '#000000' },
    { name: 'msapplication-config', content: '/favicons/atlantisbahamas/browserconfig.xml' },
    { name: 'theme-color', content: '#000000' },
  ],
  link: [
    { rel: 'stylesheet', href: 'https://use.typekit.net/mbc1jbt.css' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/atlantisbahamas/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicons/atlantisbahamas/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicons/atlantisbahamas/favicon-16x16.png' },
    { rel: 'manifest', href: '/favicons/atlantisbahamas/site.webmanifest' },
    { rel: 'mask-icon', href: '/favicons/atlantisbahamas/safari-pinned-tab.svg', color: '#5bbad5' },
    { rel: 'shortcut icon', href: '/favicons/atlantisbahamas/favicon.ico' },
  ],
  script: [
    { src: '//fast.wistia.com/assets/external/E-v1.js', async: true },
  ],
})

const route = useRoute()
const pageStore = usePageStore()
const isVoixTemplatizer = inject('isVoixTemplatizer', false)
const config = useRuntimeConfig()
const { data: mainMenu } = await useFetch(
  `${config.public.voixApiBaseUrl}/api/sites/${pageStore.currentPage?.site_id}/menus/Glue%20Main%20Navigation`,
)

const nochrome = route.query.nochrome

const pageField = usePageField()
const hidePopup = pageField.get('hidePopup', false)
const hideNavigation = pageField.get('hideNavigation', false)
</script>

<template>
  <div id="app" class="relative min-h-screen npiwff-theme">
    <div class="bg-red-500 text-white py-4">
      <div class="container text-center">
        <div class="font-sans2 text-sm">
          Join us in Paradise from March 12th to 16th, 2025
        </div>
      </div>
    </div>

    <div id="glue" class="text-zinc-700 bg-white" :class="{ nochrome }">
      <div class="relative z-40">
        <div id="portal-modal" />
      </div>

      <div class="flex justify-center">
        <div class="relative z-10 big-container overflow-hidden bg-white">
          <div v-if="(!nochrome && pageStore.currentPage?.site_id !== 4) && !hideNavigation.value">
            <WffNavigation />
          </div>

          <div
            class="relative"
          >
            <slot />
          </div>

          <div v-if="!nochrome" class="relative z-0">
            <WffFooter />
          </div>
        </div>
      </div>
      <GlobalUIHelpBot />
      <ClientOnly>
        <GlobalUIPopupForm
          v-if="!hidePopup.value"
          form="popup-npiwiff"
          image="/imgs/newsletter-signup-form/wine-and-food-festival.jpg"
          title="SIGN UP FOR SPECIAL OFFERS AND MORE"
          terms="You agree to receive marketing emails from us. You can opt out at any time"
          travel-agent="Are you a travel agent?"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<style lang="scss">
@import '~/assets/sass/npiwff.scss';
</style>
