<script setup lang="ts">
import { useEventListener } from '@vueuse/core'

const open = ref(false)
const navbarState = ref('normal')
const navbarFixed = computed(() => {
  if (navbarState.value === 'animate')
    return 'fixed top-[-200px]'
  else if (navbarState.value === 'fixed')
    return 'fixed top-0'
  return 'relative'
})

onMounted(() => {
  useEventListener(window, 'scroll', () => {
    if (window.scrollY > 500)
      navbarState.value = 'fixed'
    else if (window.scrollY > 200)
      navbarState.value = 'animate'
    else
      navbarState.value = 'normal'
  })

  try {
    // Showclix Widget
    const widget = document.getElementById(`showclix-widget-46173760-51a9-40e2-896c-71a54427bd31`)
    const script = document.createElement('script')
    script.src = `https://www.showclix.com/embed/46173760-51a9-40e2-896c-71a54427bd31`
    script.async = true

    // Add Widget to DOM
    if (widget)
      widget.appendChild(script)
  }
  catch (error) {
    console.error(error)
  }
})
</script>

<template>
  <div>
    <!-- Navigation Placeholder -->
    <div
      v-if="navbarState === 'animate'"
      class="w-full h-[144px]"
    />

    <!-- Desktop Navigation -->
    <div
      class="flex items-center justify-between z-[9999] bg-white py-6 px-8 w-full big-container font-sans2 transition-all duration-1000 ease-in-out"
      :class="navbarFixed"
    >
      <div class="flex justify-center min-[1400px]:justify-start items-center w-full min-[1400px]:w-auto">
        <button class="min-[1400px]:hidden text-glueblue-600 absolute min-[1400px]:relative left-8 min-[1400px]:left-0" @click="open = true">
          <svg
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round" stroke-linejoin="round"
              d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
            />
          </svg>
        </button>
        <a href="/wine-and-food-festival">
          <img src="/imgs/wff/npiwff-logo.png" alt="NPIWFF Logo" class="w-48">
        </a>
      </div>

      <div class="flex grow justify-end text-right">
        <ul class="relative uppercase hidden min-[1400px]:flex items-center space-x-4 text-sm  font-wff-headline font-bold">
          <li>
            <a href="/wine-and-food-festival/events" class="text-glueblue-600">Events</a>
          </li>
          <li>
            <a href="/wine-and-food-festival/chefs" class="text-glueblue-600">Talent</a>
          </li>
          <li>
            <a href="/wine-and-food-festival/faqs" class="text-glueblue-600 whitespace-nowrap text-ellipsis overflow-hidden">Info &amp; FAQs</a>
          </li>
          <li>
            <a href="/wine-and-food-festival/packages" class="text-glueblue-600">Packages</a>
          </li>
          <li>
            <a href="/wine-and-food-festival/event-photos" class="text-glueblue-600">Gallery</a>
          </li>
          <li>
            <a href="/wine-and-food-festival/a-culinary-adventure" class="text-glueblue-600 whitespace-nowrap text-ellipsis overflow-hidden">Culinary Stories</a>
          </li>
          <li>
            <a href="/wine-and-food-festival/hotels" class="text-glueblue-600 whitespace-nowrap text-ellipsis overflow-hidden">Explore Paradise</a>
          </li>
        </ul>
      </div>

      <div class="hidden min-[1400px]:flex items-center relative ml-4 space-x-2 pr-2">
        <a
          class="text-sm flex-none font-showclix inline-block text-center rounded-full border-2 py-4 px-6 border-wff-primary bg-wff-primary text-white duration-200 uppercase tracking-widest mx-0"
          href="https://www.showclix.com/tickets/wine-and-food-festival"
        >
          Get Tickets
        </a>
        <a
          class="text-sm flex-none font-showclix inline-block text-center rounded-full border-2 py-4 px-6 border-wff-secondary bg-wff-secondary text-white duration-200 uppercase tracking-widest mx-0"
          href="#booknow"
        >
          Room + Ticket
        </a>
      </div>
    </div>

    <!-- Mobile Navigation -->
    <div
      class="fixed z-[99999] top-0 left-0 h-screen w-screen bg-faw-light-blue p-8 font-sans2 text-slate-600 transform transition-all duration-300 ease-in-out"
      :class="{ '-translate-x-full': !open, 'translate-x-0': open }"
    >
      <div class="bg-white -mx-8 -mt-8 p-8 pb-0">
        <div class="flex items-center justify-between border-b border-glueblue-600 pb-4">
          <a href="/wine-and-food-festival">
            <img src="/imgs/wff/npiwff-logo.png" alt="NPIWFF Logo" class="w-40">
          </a>
          <button class="text-glueblue-600" @click="open = false">
            <svg
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              class="w-6 h-6"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>

      <ul class="pt-8 uppercase items-center space-y-4">
        <li>
          <a href="/wine-and-food-festival" class="text-glueblue-600">Festival</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/events" class="text-glueblue-600">Events</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/chefs" class="text-glueblue-600">Talent</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/faqs" class="text-glueblue-600">Info &amp; FAQs</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/packages" class="text-glueblue-600">Packages</a>
        </li>
        <!-- <li>
          <a href="/wine-and-food-festival/sponsors" class="text-glueblue-600">Sponsors</a>
        </li> -->
        <li>
          <a href="/wine-and-food-festival/event-photos" class="text-glueblue-600">Gallery</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/a-culinary-adventure" class="text-glueblue-600">Culinary Stories</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/hotels" class="text-glueblue-600">Explore
            Paradise</a>
        </li>
        <li>
          <a
            class="text-sm flex-none font-showclix inline-block text-center rounded-full border-2 py-4 px-6 border-wff-primary bg-wff-primary text-white duration-200 uppercase tracking-widest mx-0"
            href="https://www.showclix.com/tickets/wine-and-food-festival"
          >
            Get Tickets
          </a>
        </li>
        <li>
          <a
            class="text-sm flex-none font-showclix inline-block text-center rounded-full border-2 py-4 px-6 border-wff-secondary bg-wff-secondary text-white duration-200 uppercase tracking-widest mx-0"
            href="#booknow"
          >
            Room + Ticket
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>
